// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
//= require common

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'croppie/croppie';
// import $ from 'jquery';
// import Quill from 'quill';
// import 'bootstrap/dist/js/bootstrap';
import('styles/application.scss');

$(document).ready(function() {
  $('#askAttorney').click(function() {
    $('#modal-default').modal('toggle')
  })

  $('.dropdown-toggle').dropdown();

  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body',
  });

  $('body').popover({
    selector: '[data-toggle="popover"]',
    container: 'body',
    html: true,
    trigger: 'hover',
  });

  $(".types input.custom-control-input").on('click', function(e){
    location.href = e.target.getAttribute('data-uri')
  });

  $('#practice_area_select').on('changed.bs.select', function () {
    const vals = [];
    $('#practice_area_select').selectpicker('val').forEach(function (id) {
      vals.push({
        subcategory_id: parseInt(id)
      });
    });
    $('#practice_areas_attributes').attr('value', JSON.stringify(vals));
  });
});
